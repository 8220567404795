div.pick-method-tap
{
    div.tap-wrapper
    {
        background-color: purple;
        background-color: rgba(255, 230, 0, 0.25);
        position: relative;
        height: 100%;
        height: 300px;
        border-radius: 20px;
        border: 1px solid #333;
        padding: 20px;
        cursor: pointer;
    }

    div.tap-wrapper[data-clicked-state="true"]
    {
        background-color: rgba(255, 230, 0, 0.1);
    }

    div.tap-to-pick
    {
        text-align: center;
        margin: 30px;
    }

    div.tap-clickable-area
    {
        //unused classes
        background-color: rgba(255, 230, 0, 0.25);
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 20px;

        text-align: center;
        vertical-align: baseline;

        span
        {
            display: inline-block;
            padding-top: 200px;
        }
    }

    div.balls
    {
        display: flex;
        height: 150px;

        div.non-ball
        {
            flex: 0.2;
        }

        div.ball
        {
            flex: 0.2;
            background-position: center;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;

            text-align: center;

            span
            {
                font-size: 3rem;
                display: inline-block;
                margin-top: 30px;
            }
        }
    }
}

@media screen and (max-width: 600px)
{
    div.pick-method-tap
    {
        div.balls
        {
            div.non-ball
            {
                flex: 0;
                display: none;
            }

            div.ball
            {
                flex: 1 !important;
            }
        }
    }
}
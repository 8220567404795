div.games-all-games {

    div.search-games {
        margin-bottom: 10px;        
    }

    div.game-sections {
        //background-color: orange;
        display: flex;
        flex-direction: row;
        justify-content: center;
        border: 1px solid #444;
        background-color: #111;
        border-radius: 10px;
        margin-bottom: 10px;

        button:first-child {
            border-radius: 10px 0 0 10px;
        }

        button:last-child {
            border-radius: 0 10px 10px 0;
        }

        button {
            background: none;
            outline: none;
            border: none;
            flex: 1;
            text-align: center;
            font-size: 1.5rem;
            text-decoration: none;
            color: #fff;
        }

        button[data-selected="true"] {
            //background-color: orange;
            //background-color: rgba(227, 102, 0, 0.9);
            //background-color: #ebd71e;
            background-color: rgba(235, 215, 30, 1);
            //color: #111;
            font-weight: bold;
            text-shadow: #111 1px 1px;
        }
    }

    div.games-list {
        display: flex;
        flex-direction: column;
        padding: 20px 20px;
        width: 100%;
    }

    div.games-favorites-list {
        border-bottom: 1px solid #ffe600;
        background-color: rgba(255, 230, 0, 0.25);
        border-radius: 5px;
    }
}

@media screen and (max-width: 600px) {
    div.games-all-games {
        div.game-sections {
            display: block;
            border-radius: 0;
            border-width: 0 0 1px 0;
            background-color: initial;

            button {
                border-radius: 5px !important;
            }
        }

        div.games-list {
            padding: 20px 10px;
        }
    }
}
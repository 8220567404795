div.page-translations {
    height: 100 vh;
    text-align: center;
    margin: 50 px auto auto;

    h1
    {
        font-family: Lobster;
        margin: 10px 0 50px 0;
    }
}

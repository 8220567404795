div.page-game-details
{    

    div.pick-types
    {
        display: flex;
        flex-direction: column;
        align-items: center;

        >button
        {
            display: block;
            //background-color: #005ACA;
            background-color: rgba(0, 90, 202, 0.7);
            color: #fff;
            font-family: "Lobster";
            font-size: 2.5rem;
            padding: 10px 0;
            width: 400px;
            border-radius: 20px;
            border: 2px solid #fff;
            margin-bottom: 20px;
        }
    }

    div.completed-rounds
    {
        text-align: center;
        margin-bottom: 10px;
    }

    div.after-pick-links
    {
        margin-top: 50px;

        >div
        {
            text-align: center;
            margin-bottom: 10px;

            >span
            {
                font-family: "Lobster";
                font-size: 3rem;
                cursor: pointer;
            }
        }
    }
}

div.page-game-recent-winning-numbers
{
    div.winning-numbers-list
    {
        //background-color: orange;        
    }

    div.winning-numbers-item
    {
        margin-bottom: 40px;
        padding-bottom: 20px;
        border-bottom: 5px solid #333;
    }

    div.winning-numbers-date
    {
        text-align: center;
        font-size: 2rem;
        margin-bottom: 10px;
    }
}



div.page-game-analyze
{
    div.no-picks-yet
    {
        border: 1px solid #333;
        padding: 20px;
        border-radius: 10px;
        font-size: 1.5rem;
        text-align: center;
        background-color: #222;
    }
}



@media (max-width: 600px)
{
    div.page-game-details
    {
        div.pick-types
        {
            display: flex;
            flex-direction: column;
            align-items: center;

            >button
            {
                width: 80%;
            }
        }
    }
}
div.main-app {
    margin-top: 30px;
}

div.main-content {
    min-height: 800px;
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.5);
    //background-color: purple;
    padding: 20px;
    border: 1px solid #333;
    border-radius: 0 0 20px 20px;
    padding-bottom: 100px;
}

div.test-image-ball-primary {
    height: 50px;
    width: 50px;
    background-image: url("/images/balls--primary-01.png");
    background-size: contain;
}
div.test-image-ball-secondary {
    height: 50px;
    width: 50px;
    background-image: url("/images/balls--secondary-01.png");
    background-size: contain;
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 600px) {
    div.main-content {
        padding: 10px;
    }

    div.main-app {
        margin-top: 10px;
    }
    
}

div.pick-method-blind {
    
    div.status-text
    {
        font-size: 2rem;
        color: #ffffff;
        text-align: center;
        margin-bottom: 10px;
    }

    div.game-picks
    {
        margin-bottom: 20px;
    }
    
    div.wrapper {
        display: flex;
    }    

    div.row-line {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    div.card-wrapper {
        justify-content: center;
        display: flex;
        align-items: center;
        //aspect-ratio: 1;
        //flex-basis: 10%;        
    }

    div.card {
        background-color: green;
        justify-content: center;
        align-items: center;
        margin: 2px;
        border: 1px solid #dcdcdc;
        border-radius: 10px;
        flex: 1;
        text-align: center;
        color: #fff;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }

    div.card:hover {
        opacity: .5;
    }

    div.card-wrapper[data-ball-type="primary"] {
        div.card {
            background: #0063bf;
        }
    }

    div.card-wrapper[data-ball-type="secondary"] {

        div.card {
            background: #e30000;
        }
    }


    div.card-wrapper[data-selected="true"] {
        div.card {
            background-color: #dcdcdc !important;
            color: #333;
        }
    }
}
div.section-title
{
    text-align: center;
    border-radius: 10px;
    background-color: rgba(1, 89, 205, .5);
    margin: 20px 0 25px 0;
}

div.game-item
{
    //background-color: orange;
    display: flex;
    border-bottom: 1px solid #333;
    padding: 0 0 10px 5px;
    margin-bottom: 10px;
    //align-items: center;
    align-items: flex-start;

    div.image
    {
        width: 75px;
        margin-right: 30px;

        img
        {
            width: 100%;
        }
    }

    div.title
    {
        flex: 1;

        div.details
        {

            //background-color: yellow;
            display: flex;
            align-items: flex-start;

            div.drawing-days
            {
                font-size: 1rem;
                flex: 1;
            }

            div.playing-today
            {
                font-size: .9rem;
                background: #038a10;
                border-radius: 10px;
                padding: 2px 15px;
                padding-top: 1px;
            }
        }
    }

    div.star
    {
        padding: 0 20px;
        font-size: 2rem;

        i.fa-star
        {
            color: #999;
        }
    }

    div.star:hover
    {
        cursor: pointer;
    }

    //background-color: blue;
    a
    {
        text-decoration: none;
        color: #fff;
        font-family: "Lobster";
    }
}

div.game-item:hover
{
    background-color: rgba(0, 90, 202, 0.3);
}

div.game-item[data-is-favorite="true"]
{
    div.star
    {
        i.fa-star
        {
            color: #ffe600;
        }
    }
}

@media screen and (max-width: 600px)
{
    div.game-item
    {
        div.star
        {
            i.fa-star
            {
                font-size: 1.7rem;
            }
        }

        div.image
        {
            margin-right: 10px;
        }
    }
}
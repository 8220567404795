div.container {
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    div.container {
        max-width: 100%;
    }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
    div.container {
        max-width: 100%;
    }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
    div.container {
        max-width: 100%;
    }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
    div.container {
        max-width: 1140px;
    }
}

// X-Large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {
    div.container {
        max-width: 1140px !important;
    }
}

// XX-Large devices (larger desktops)
// No media query since the xxl breakpoint has no upper bound on its width

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
    div.container {
        max-width: 1140px !important;
    }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
}

// X-Large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {
}

// XX-Large devices (larger desktops)
// No media query since the xxl breakpoint has no upper bound on its width

div.page-my-picks
{
    h1
    {
        margin-bottom: 10px;
    }

    h2
    {
        color: #fff;
    }

    div.top-functions
    {
        display: flex;
        margin-bottom: 20px;

        button
        {
            flex: 1;
            margin: 0 10px;
            border-radius: 10px;
            outline: none;
            background: none;
            color: #fff;
            padding-bottom: 5px;
            border: 2px solid #fff;
        }

        button.toggle
        {
            background-color: #999;
            color: #fff;
            //border-color: #333;
        }

        button.delete
        {
            background-color: #bd0000;
            border-color: #dcdcdc;
        }
    }

    div.winning-numbers-list
    {
        div.winning-numbers-item
        {
            margin-bottom: 50px;
            padding-bottom: 25px;
            border-bottom: 5px solid #333;
        }
    }

    div.winning-numbers-text-view
    {
        margin: 20px;

        textarea
        {
            border-radius: 10px;
            border: 1px solid #333;
            width: 100%;
            height: 600px;
            padding: 10px;
        }
    }

    div.winning-numbers-empty
    {
        border: 1px solid #333;
        padding: 20px;
        border-radius: 10px;
        font-size: 1.5rem;
        text-align: center;
        background-color: #222;
        margin-top: 50px;
    }
}

@media screen and (max-width: 600px)
{
    div.page-my-picks
    {
        div.top-functions
        {
            button
            {
                font-size: 1.2rem;
                border-radius: 5px;
                border-width: 1px;
            }
        }
    }
}
$border-color: rgba(150, 150, 150, 1);

div.age-disclaimer {
    font-size: 0.9rem;
    border-radius: 10px;
    border: 2px solid $border-color;
    margin: 20px 50px 20px 50px;
    background-color: rgba(100, 100, 100, 0.4);
    > div {
        flex: 1;
        text-align: center;
        padding: 10px 5px;
        border-right: 2px solid $border-color;
    }
    > div:last-child {
        border-width: 0;
    }

    > div,
    a {
        color: rgb(200, 200, 200);
        //text-shadow: 1px 1px 4px rgba(10, 10, 10, .5);
    }
}

@media screen and (max-width: 600px) {
    div.age-disclaimer {
        margin: 20px 0;
    }
}

div.page-home {
    height: 100vh;
    //background-color: pink;
    display: flex;
    flex-direction: column;
    text-align: center;

    h3
    {
        font-size: 1.3rem;
    }

    div.title {
        //background-color: orange;
        height: 20vh;
        padding: 20px 0;

        img {
            height: 100%;
        }
    }

    div.logo {
        //background-color: blue;
        height: 40vh;
        padding: 0 0 20px 0;

        img {
            height: 100%;
        }
    }

    div.description {
        margin-bottom: 20px;

        h2 {
            margin: 5px 0;
            margin-bottom: 20px;
            color: #fff;
        }
    }

    div.links {
        //background-color: red;
        height: 25vh;

        a {
            display: inline-block;
            padding: 0 50px 30px 0;
        }
    }

    div.feedback {
        margin: 20px 0 10px 0;
        padding: 0;

        a {
            font-family: Lobster;
            color: #fff;
            padding: 0;
        }
    }

    div.translation-issues {
        padding: 0;
        padding-bottom: 30px;

        a {
            font-family: Lobster;
            color: #fff;
            padding: 0;
        }
    }

    div.jackpocket {
        text-align: center;

        a {
            padding: 0;
        }

        img {
            max-width: 400px;
        }
    }    
}

@media screen and (max-width: 900px) {
    div.page-home {
        div.description {
            margin-bottom: 10px;

            h2 {
                margin: 5px 0;
                margin-bottom: 20px;
                color: #fff;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    div.page-home {
        padding: 20px;

        div.title {
            height: initial;
            padding: 10px 0;

            img {
                height: initial;
                width: 100%;
            }
        }

        div.logo {
            height: initial;
            padding: 20px 40px;

            img {
                height: initial;
                width: 100%;
            }
        }

        div.links {
            height: initial;

            img {
                height: initial;
                width: 100%;
            }
        }

        div.links {
            a {
                padding: 0;
                padding-bottom: 30px;
                //display: block;
            }
        }
    }
}
div.page-purchase-tickets {
    div.games-list {
        text-align: center;

        >div {
            margin-bottom: 20px;
        }

        h2 {
            text-align: center;
        }
    }

    h1 a {
        color: #fff;
        border-bottom: 2px solid rgba(0, 90, 202, 0.8);
    }

    div.jackpocket {
        margin-bottom: 20px;
        padding: 0 30px;
        text-align: center;

        a {
            padding: 0;
        }

        img {
            max-width: 400px;
        }
    }

    div.the-lotter {
        text-align: center;
        color: #fff;
    }
}

@media screen and (max-width: 803px) {
    div.page-purchase-tickets {
        div.games-list {
            img {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 700px) {
    div.page-purchase-tickets {
        div.jackpocket {
            margin-bottom: 20px;
            padding: 0 30px;

            img {
                width: 100%;
            }
        }
    }
}
footer {
    //background-color: pink;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 100px;

    img {
        max-height: 100px;
    }

    div.links {
        margin-top: 20px;

        > a {
            display: inline-block;
            margin: 0 20px;
            margin-bottom: 20px;
        }
    }

    div.feedback {
        margin: 30px 0 10px 0;
        padding: 0;
        a {
            font-family: Lobster;
            color: #fff;
            padding: 0;
        }
    }

    div.translation-issues {        
        padding: 0;
        a {
            font-family: Lobster;
            color: #fff;
            padding: 0;
        }
    }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 600px) {
    footer {
        margin-top: 50px;
    }
}

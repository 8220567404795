html
{
    min-height: 100%;
}

body
{
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    color: #fff;
    font-size: 2rem;
}

a,
a:hover,
a:visited
{
    color: #fff;
}

// .app {
//     text-align: center;
// }

// .app-header {
//     min-height: 100vh;
//     max-height: 100px;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     font-size: calc(10px + 2vmin);
//     color: white;
//     margin-top: 10px;
//     margin-bottom: 10px;
// }

// .app-header img
// {
//     height: 100%;
// }

// .app-title {
//     height: 20vh;
//     margin-bottom: 50px;
// }

// .app-logo {
//     height: 35vh;
// }

// .app-links {

//     height: 25vh;
// }

// @media screen and (max-width: 600px) {
//     .app-header {
//         justify-content: initial;
//         padding: 20px 20px 0 20px;
//     }

//     .app-title {
//         height: initial;
//         margin-bottom: 20px;
//     }

//     .app-logo {
//         height: initial;
//     }

//     .app-description {
//         height: initial;
//     }

//     .app-links {
//         height: initial;
//     }

//     img {
//         width: 100%;
//     }
// }
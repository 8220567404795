div.pick-method-release-to-pick
{
    div.wrapper
    {
        //backgroundColor: "red",
        display: flex;
        min-height: 200px;
        margin-bottom: 15px;
        cursor: pointer;
    }

    // const styles__current_number__view = {
    //     //backgroundColor: "orange",
    //     alignItems: "center",
    //     justifyContent: 'center',
    // };

    // const styles__current_number__image = {
    //     height: screen__get_multiple_from_height(75),
    //     position: "absolute",
    // };

    // const styles__current_number__text = {
    //     fontSize: fonts__get_size(25),
    // };


    div.tap
    {
        //display: flex;
        margin: 20px;
        cursor: pointer;
        >div
        {
            text-align: center;
        }
    }

    // const styles__tap__text = {
    //     flex: 1,
    //     textAlign: "center",
    //     fontSize: fonts__get_size(50),
    // };
}
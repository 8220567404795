div.game-picks {
    div.game-line {
        //background-color: red;
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;

        > div {
            background-size: contain;
            background-repeat: no-repeat;
            //align-items: center;
            //align-self: center;
            //background-color: green;
            text-align: center;            
            background-position: center;            
            height: 100px;
            // margin-left: 10px;
            // margin-right: 10px;

            span {     
                display: inline-block;           
                text-align: center;
                margin-top: 20px;
                font-size: 2rem;
            }
        }
    }

    div.first-line {
        > div {
            background-image: url("/images/balls--primary-01.png");
        }
    }
    div.second-line {
        > div {
            background-image: url("/images/balls--secondary-01.png");
        }
    }
}


// X-Small devices (portrait phones, less than 576px)
@media screen and (max-width: 700px) {
    div.game-picks {
        div.game-line {
            
            > div {
                height: 80px;
                margin-left: 5px;
                margin-right: 5px;

                span {     
                    font-size: 1.4rem;
                    margin-top: 20px;
                }
            }
        }
    }
}
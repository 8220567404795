div.games-header
{

    div.top-functions
    {
        line-height: 1rem;
        text-align: right;

        button.delete
        {
            line-height: 1rem;
            color: #fff;
            font-size: 1rem;
            background-color: #444;
            display: inline-block;
            border-radius: 5px;
            border: 1px solid #666;
            padding: 3px 10px;
        }

        button.delete[data-has-picks="true"]
        {
            background-color: #bd0000;
        }
    }

    div.title-line
    {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;

        h1
        {
            text-align: center;
            font-family: "Lobster";
            font-size: 4rem;
            margin-bottom: 20px;
        }

        img
        {
            width: 200px;
            margin-right: 30px;
        }
    }

    div.links
    {
        //background-color: red;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;

        a
        {
            flex: 1;
            text-align: center;
            font-family: Lobster;
            color: #005aca;
            color: #fff;
            font-size: 2rem;
            text-decoration: none;
            padding-bottom: 10px;
            padding: 20px 0;
            line-height: 2.2rem;
        }

        a.selected
        {
            //background-color: rgba(255, 230, 0, .25);
            background-color: rgba(255, 230, 0, 0.25);
            border-bottom: 5px solid rgba(255, 230, 0, 0.25);
            border-radius: 5px 5px 0 0;
            //border-radius: 10px;
        }
    }

    div.info
    {
        margin: 0 20px 20px 20px;
        border: 1px solid #999;
        border-radius: 10px;
        padding: 5px 20px 20px 20px;

        div.drawing-days
        {
            text-align: center;
            margin-bottom: 10px;
        }

        div.current-jackpot
        {
            text-align: center;
            margin-bottom: 10px;
        }

        div.rules
        {
            display: flex;
        }

        div.game-line
        {
            flex: 1;
        }

        div.first-line
        {}

        div.second-line
        {}
    }
}

@media screen and (max-width: 700px)
{
    div.games-header
    {
        div.title-line
        {
            margin-bottom: 10px;

            h1
            {
                font-size: 2rem;
            }

            img
            {
                width: 50%;
                height: auto;
                margin-right: 10px;
            }
        }

        div.links
        {
            a
            {
                font-size: 1.5rem;
                line-height: 1.7rem;
            }
        }

        div.info
        {
            margin: 0;
            font-size: 1.5rem;
            margin-bottom: 10px;
        }
    }
}
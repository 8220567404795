
//colors
//blue: #005BCC

h1
{
    color: #fff;
    font-size: 2rem;
}

a
{
    text-decoration: none;
}
a:visited
{
    color: #fff;
}



@media screen and (max-width: 600px) {
   h1
   {
       //font-size: 1rem;
   }
}

header.app-header {
    //background-color: pink;
    text-align: center;
    //margin-bottom: 20px;

    img {
        max-height: 100px;
        z-index: 100;
    }

    div.links {
        //background-color: #005ACA;
        //background-color: #111;
        background-color: rgba(0, 90, 202, 0.8);
        z-index: 1;
        display: flex;
        flex-direction: row;
        justify-content: center;
        border: 1px solid #444;
        border-radius: 30px;
        border-radius: 30px 30px 0 0;
        margin-top: 20px;
        margin-top: -50px;
        margin-bottom: 10px;
        margin-bottom: 0;

        div.spacer {
            width: 150px;
            //background-color: orange;
        }

        > div {
            flex: 1;
            padding-top: 50px;
            padding-bottom: 10px;
            text-align: center;
            // background-color: red;
            // border: 1px solid green;
        }

        a {
            z-index: 2;
            font-size: 2.5rem;
            text-decoration: none;
            color: #fff;
            font-family: Lobster;
            text-shadow: 3px 3px 2px rgba(0, 0, 0, 0.7);
        }
        a:hover {
            //background-color: rgba(0, 90, 202, 0.5);
        }
        a:first-child {
            //border-right: 1px solid #999;
        }
    }
}

@media screen and (max-width: 700px) {
    header.app-header {
        img {
            margin-bottom: 20px;
        }

        div.links {
            margin-top: 0;

            > div {
                padding-top: 15px;
                line-height: 2rem;
            }

            a {
                font-size: 1.5rem;
                //line-height: 1rem;
            }
        }
    }
}
